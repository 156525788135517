import { AusgabenDataModel } from "../AusgabenDataModel";
import { BezugsvorschlagModel } from "../BezugsvorschlagDataModel";
import Datum from "@/util/datum";
import { FilialeModel } from "../FilialeModel";
import { ZeitschriftFlatModel } from "../ZeitschriftDataModel";
import { catVue } from "@/util/logging";
import { parseISO } from "date-fns";

export interface FilialenTabelle {
  nr: number;
  kurz: string;
  lang: string;
}

export class MengenDisplay {
  id: number; // Ausgabe ID (Ausgangspunkt)

  loading = true;
  loaded = true;
  loadStep: number;
  loadTarget: number;

  ztg: number;
  ztgObj: ZeitschriftFlatModel;
  vdz: number;
  titel: string;
  erscheinen: string;

  verlag: string;
  vAnschrift: string;

  ausgabe = "";
  ausgabeTitel = "";
  ausgabeEVT = "";
  ausgabeEVTDate: Date | null;
  ausgabeDispo = "";
  ausgabeDispoDate: Date | null;

  vorAusgabe: Array<string> = [];
  vorAusgabeTitel: Array<string> = [];
  vorAusgabeDispo: Array<string> = [];

  nachAusgabe = "";
  nachAusgabeTitel = "";
  nachAusgabeDispo = "";

  aktLieferung: Record<number, number>;
  aktClass: Array<string>;

  vorStep: number | null = null;
  vorLieferung: Array<Record<number, number>>;
  vorVerkauf: Array<Record<number, number>>;
  vorClass: Array<Array<string>>;

  letzteDispo: Record<number, number>;
  letzteDispoDatum: Record<number, string>;

  filialen: FilialeModel[];

  bzvValues: number[];
  bzvKommentar = "";

  messages: string[] = [];

  constructor(
    id: number,
    first: AusgabenDataModel,
    ztg: ZeitschriftFlatModel,
    ausgaben: AusgabenDataModel[]
  ) {
    const filialen = FilialeModel.filialenAktiv();

    this.id = id;
    this.loadStep = 0;
    this.loadTarget = 2 + filialen.length;

    this.ztg = first.ztg;
    this.ztgObj = ztg;
    this.vdz = ztg.vdz_nr;
    this.titel = ztg.titel;
    this.erscheinen = ztg.erscheinen;
    this.verlag = ztg.verlag;
    this.vAnschrift = ztg.v_anschrift;

    this.ausgabe = first.ausgabe;
    this.ausgabeTitel = null == first.untertitel ? "" : first.untertitel;
    this.ausgabeEVTDate =
      first.evt != "" && first.evt != null ? parseISO(first.evt) : null;
    this.ausgabeEVT = Datum.p5dD(first.evt, "kein EVT");
    this.ausgabeDispoDate =
      first.dispo != "" && first.dispo != null ? parseISO(first.dispo) : null;
    this.ausgabeDispo = Datum.p5dD(first.dispo, "kein Dispotermin");

    this.aktLieferung = {};
    this.aktClass = [];

    this.vorLieferung = [];
    this.vorVerkauf = [];
    this.vorClass = [];

    this.letzteDispo = {};
    this.letzteDispoDatum = {};

    this.filialen = filialen;

    this.bzvValues = [];

    for (const line of ausgaben) {
      this.aktLieferung[line.f_num] = line.lfg;
      if (!FilialeModel.isAktiv(line.f_num)) this.bumpTarget();
    }
  }

  public getMessages(): string {
    if (this.messages.length < 1) return "?";
    let ret = this.messages[0];
    for (let i = 1; i < this.messages.length; i++) {
      ret = ret + "<br />" + this.messages[i];
    }
    return ret;
  }

  private bumpTarget(): void {
    if (this.filialen.length != FilialeModel.filialenAlle().length) {
      //            catVue.info("Bumping for " + this.titel + " " + this.ausgabe)
      this.loadTarget =
        this.loadTarget -
        this.filialen.length +
        FilialeModel.filialenAlle.length;
      this.filialen = FilialeModel.filialenAlle();
    }
  }

  public showAdd(step: number): boolean {
    if (null == this.vorStep) return false;
    if (
      step - 1 == this.vorStep &&
      this.vorAusgabe[step - 1] != null &&
      this.vorAusgabe[step - 1].length > 0
    )
      return true;

    return false;
  }

  public addVorVor(): void {
    if (this.vorStep !== null) this.fillVorausgabe(this.vorStep + 1);
  }

  // Vor-Ausgabe (0->vor-vorausgabe, 1 -> vor-vor-vorausgabe etc.)
  //
  public fillVorausgabe(step: number): void {
    if (this.vorStep === null || this.vorStep < step) {
      const start = null !== this.vorStep ? this.vorStep + 1 : 0;
      this.vorStep = step;
      for (let theStep = start; theStep <= this.vorStep; theStep++) {
        if (theStep > 0) this.loadTarget++;
        this.vorLieferung[theStep] = [];
        this.vorVerkauf[theStep] = [];
        this.vorClass[theStep] = [];

        AusgabenDataModel.deepByID(this.id, 1, -(2 + theStep))
          .then((vorAusgaben) => {
            //
            // Only first step updates vorAusgabe field
            //
            if (vorAusgaben.length) {
              this.vorAusgabe[theStep] = vorAusgaben[0].ausgabe;
              this.vorAusgabeDispo[theStep] =
                null == vorAusgaben[0].dispo
                  ? "kein Dispotermin"
                  : vorAusgaben[0].dispo;
            } else {
              this.vorAusgabe[theStep] = "kein Vorgänger";
              this.vorAusgabeDispo[theStep] = "-/-";
            }

            for (const line of vorAusgaben) {
              const fnum = line.f_num;

              this.vorLieferung[theStep][fnum] = line.lfg;
              this.vorVerkauf[theStep][fnum] = line.verkauf;

              if (
                0 == this.vorVerkauf[theStep][fnum] &&
                this.vorLieferung[theStep][fnum] > 0
              ) {
                this.messages.push(
                  "Nullverkauf in " + FilialeModel.get(fnum)?.f_kurz
                );
                this.vorClass[theStep][fnum] = "nullverkauf";
              }

              if (
                this.vorLieferung[theStep][fnum] ==
                  this.vorVerkauf[theStep][fnum] &&
                this.vorVerkauf[theStep][fnum] > 0
              ) {
                this.messages.push(
                  "Ausverkauf in " + FilialeModel.get(fnum)?.f_kurz
                );
                this.vorClass[theStep][fnum] = "abverkauf";
              }

              if (
                0 == theStep &&
                this.aktLieferung[fnum] < this.vorLieferung[theStep][fnum] &&
                this.aktLieferung[fnum] > 0
              ) {
                this.messages.push(
                  "Reduzierung in " + FilialeModel.get(fnum)?.f_kurz
                );
                this.aktClass[fnum] = "reduzierung";
              }

              if (!FilialeModel.isAktiv(fnum)) this.bumpTarget();
            }

            this.loadStep++;
          })
          .catch((error) => {
            catVue.info("Error loading Vor-Ausgabe ..." + error);
          });
      }
    } else {
      catVue.info(
        "Ignoring loading Vor-Ausgabe because we are already ahead ..."
      );
    }
  }

  // Nach-Ausgabe
  //
  public fillNachausgabe(): void {
    AusgabenDataModel.deepByID(this.id, 0, 1)
      .then((nachAusgaben) => {
        if (nachAusgaben.length > 0) {
          const nach = nachAusgaben[0];
          this.nachAusgabe = nach.ausgabe;
          this.nachAusgabeTitel =
            null == nach.untertitel ? "" : nach.untertitel;
          this.nachAusgabeDispo = Datum.p5dD(nach.dispo, "kein Dispotermin");
        } else {
          this.nachAusgabe = "keine Folgeausgabe";
          this.nachAusgabeDispo = "-/-";
        }
        this.loadStep++;
      })
      .catch((error) => {
        catVue.error("Problem Nachausgabe zu bekommen ..." + error, null);
        this.nachAusgabe = "Folgeausgabe nicht gefunden";
        this.nachAusgabeDispo = "?/?";
      });
  }

  //  Letzte Dispo
  //
  public fillLetzteDispo(): void {
    for (const fil of FilialeModel.filialenAlle()) {
      //
      BezugsvorschlagModel.query(fil, this.ztgObj, 1, true)
        .then((ret) => {
          //
          if (ret.length == 1) {
            const bzv = ret[0];
            this.letzteDispo[fil.nr] = bzv.menge;
            this.letzteDispoDatum[fil.nr] = Datum.p5sdD(bzv.genehmigt, "??");
          }

          this.loadStep++;
        })
        .catch((error) => {
          catVue.info("Error Fetching Bezugsvorschlag " + error);
        });
    }
  }
}
