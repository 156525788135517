import { CreatedResponse, api } from "@/util/api";
import { Exclude, Expose } from "class-transformer";

import { BaseType } from "./util/BaseType";
import { FilialeModel } from "./FilialeModel";
import { ZeitschriftFlatModel } from "./ZeitschriftDataModel";
import { catApi } from "@/util/logging";

export class BezugsvorschlagModel extends BaseType {

   @Expose()
   uid = '';

   @Expose()
   url = '';

   @Expose()
   id = -1;

   @Expose()
   datum = "";

   @Expose()
   ausgabe = "";

   @Expose()
   f_num = 0;

   @Expose()
   menge = 0;

   @Expose()
   einmal = "";

   @Expose()
   kommentar = "";

   @Expose()
   benutzer_id = -1;

   @Expose()
   genehmigt = "";

   @Expose()
   genehmiger_id = -1;

   @Expose()
   a_id = -1;

   @Expose()
   ztg = -1;

   @Exclude()
   $created = Date.now();

   // === === === === === === === === === === === === === === === ===

   static query = async (
      fil: FilialeModel | null,
      ztg: ZeitschriftFlatModel | null,
      count: number | null,
      genehmigt: boolean | null
   ): Promise<BezugsvorschlagModel[]> => {

      catApi.debug("query ... querying ");

      try {

         const para: Record<string, number | string> = {};
         if (fil != null) para['filiale'] = fil.f_num;
         if (ztg != null) para['ztg'] = ztg.id;
         if (count != null) para['count'] = count;
         if (genehmigt != null) para['genehmigt'] = (genehmigt) ? 'Y' : 'N';

         const path = 'v1/bezugsvorschlaege'
         const response = await api.get<BezugsvorschlagModel[]>(path, { params: para });
         catApi.debug(`response: /${path}?${JSON.stringify(para)} -> ${JSON.stringify(response)}`);

         const ret = BaseType.convertArray(BezugsvorschlagModel, response.data)
         return ret
         //
      } catch (e) {

         const err = new Error(JSON.stringify(e))
         catApi.error("caught Error: " + err.message, null);
         return Promise.reject(err)
      }
   }

   static create = async (
      ztg: ZeitschriftFlatModel,
      fil: FilialeModel,
      jahr: number,
      heft: number,
      menge: number,
      kommentar: string,
      einmalig: boolean,
      nachlieferung: boolean,
   ): Promise<string> => {

      try {

         const data = {
            jahr: jahr,
            heft: heft,
            menge: menge as number,
            kommentar: kommentar,
            einmalig: einmalig,
            nachlieferung: nachlieferung,
         }

         const path = `v1/bezugsvorschlaege${ztg.url}${fil.url}`
         const response = await api.post<CreatedResponse>(
            path,
            data
         )
         catApi.info(`response: /${path} (${JSON.stringify(data)}) -> ${JSON.stringify(response)}`);

         if (!api.isErrorResponse(response)) {
            const result = response.data as CreatedResponse
            return result.url
         }

         const err = new Error(JSON.stringify(response))
         return Promise.reject(err)
      }
      catch (err) {

         catApi.info("API Error/ Exception: " + err)
         return Promise.reject(err)
      }
   }
}

