


























































































import { catNav, catVue } from "@/util/logging";
import { eachDayOfInterval, format, parseISO } from "date-fns";

import { Api } from "@/util/api";
import { AusgabenDataModel } from "@/models/AusgabenDataModel";
import Datum from "@/util/datum";
import { MengenDisplay } from "@/models/util/BuchungsMonitorDisplayModel";
import { Route } from "vue-router";
import Vue from "vue";
import { ZeitschriftFlatModel } from "@/models/ZeitschriftDataModel";
import { mapState } from "vuex";

// Datums-Unterstützung
   // eslint-disable-next-line

   // eslint-disable-next-line

   export default Vue.extend({
     //
     name: "BuchungsMonitor",

     props: {
       von: {
         type: String,
         required: true,
       },
       bis: {
         type: String,
         required: true,
       },
     },

     components: {
       BuchungsMonitorDetail: () =>
         import(
           /* webpackChunkName: "BuchungsMonitorDetail" */ "@/components/statistik/BuchungsMonitorDetail.vue"
         ),
     },

     data() {
       return {
         checkCt: 0,

         lastFetch: "",
         search: "",
         needExplicitConfirm: false,
         explicitConfirmString: "",
         haveExplicitConfirm: false,

         toFetch: new Array<number>(),

         ztg: new Map<number, ZeitschriftFlatModel>(),

         mengen: new Array<MengenDisplay>(),

         options: {
           itemsPerPage: 25,
         },
       };
     },

     methods: {
       fmt(datum: string) {
         return Datum.datumDeutsch(datum);
       },

       clearAll(): void {
         this.toFetch.splice(0);
         Api.cancelAllWaiting();
         this.mengen.splice(0);
       },

       giveExplicitConfirm(): void {
         catVue.info(
           `${this.$options.name}:: giveExplicitConfirm ... ${this.von} bis ${this.bis}`
         );

         this.haveExplicitConfirm = true;
         this.fetch();
       },

       checkVonBisExplicit(): boolean {
         catVue.info(
           `${this.$options.name}:: checking ... ${this.von} bis ${this.bis}`
         );

         if (
           undefined === this.von ||
           undefined === this.bis ||
           this.von === "" ||
           this.bis === ""
         ) {
           catVue.info("Still one part NULL, nothing to be done ....");
           return false;
         }

         const thisFetch = this.von + "-" + this.bis;
         if (this.lastFetch === thisFetch) {
           catVue.info("Still the same fetch, nothing to be done ....");
           return false;
         }

         const von = parseISO(this.von);
         const bis = parseISO(this.bis);

         if (
           undefined === von ||
           bis === undefined ||
           bis.getTime() < von.getTime()
         ) {
           catVue.info("Strange Date Formats ...");
           this.$store.commit("setLastError", "Seltsame Datumsauswahl");

           return false;
         }

         const tage = eachDayOfInterval({ start: von, end: bis });
         console.log("Tage :", tage);
         if (tage.length > 1) {
           const str = `Das Zeitinterval ${format(von, "dd.MM.yy")}–${format(
             bis,
             "dd.MM.yy"
           )} umfasst ${tage.length} Tage. 
                                                                                                                      Das kann zu einer langen Ladezeit und hohem Speicherverbraucht führen.
                                                                                                                      Sollen die Daten entsprechend geladen werden?`;
           if (
             this.needExplicitConfirm == false ||
             this.explicitConfirmString != str
           ) {
             this.needExplicitConfirm = true;
             this.haveExplicitConfirm = false;
             this.explicitConfirmString = str;

             return false;
           }

           return this.haveExplicitConfirm;
         } else {
           this.needExplicitConfirm = this.haveExplicitConfirm = false;
           this.explicitConfirmString = "";
         }

         this.lastFetch = thisFetch;
         return true;
       },

       check() {
         this.checkCt++;

         setTimeout(() => {
           if (this.checkCt > 0) this.fetch();
           this.checkCt = 0;
         }, 10);
       },

       // fetch bestimmt die Ausgaben die im Scope des Buchungsmonitors sind.
       // jede Ausgabe wird dann in die `toFetch` Liste gepusht.
       // Danach wird ein `fetchNext()` auf den Stack gepusht.
       async fetch() {
         if (!this.checkVonBisExplicit()) return;

         catVue.info(
           `${this.$options.name}:: fetching ... ${this.von} bis ${this.bis}`
         );

         this.$store.commit("changeAppLoadingState", true, { root: true });
         catVue.info(`Fetching .... ${this.lastFetch}`);

         this.clearAll();

         try {
           const ausgaben = await AusgabenDataModel.inRange(this.von, this.bis);
           for (const id of ausgaben) {
             this.toFetch.push(id);
           }

           this.$nextTick(this.fetchNext);
           //
         } catch (e) {
           catVue.error("Error: " + e, null);
           this.$store.commit("changeAppLoadingState", false, { root: true });
         }
       },

       // fetchNext holt den nächsten Schwung Daten vom backend.
       // Dabei wird immer ein Schwung von Fetches in die Queue gestellt
       // und an fetchID übergeben
       fetchNext() {
         // first verify that there are even things remaining to be retrieved.
         if (0 == this.toFetch.length && Api.allDone()) {
           catVue.info("Nothing more to fetch …");
           this.$store.commit("changeAppLoadingState", false, { root: true });
           return;
         }

         for (let index = 0; index < 10 - Api.countWaiting(); index++) {
           const id = this.toFetch.shift();
           if (id !== undefined) this.fetchID(id);
         }

         setTimeout(() => {
           this.fetchNext();
         }, 150);
       },

       // fetchID lädt den BuchungsMonitor-Block für eine Ausgabe nach ID
       async fetchID(id: number) {
         this.$store.commit(
           "changePercentComplete",
           {
             current: -this.toFetch.length - 1,
             total: this.mengen.length + 1 + this.toFetch.length,
           },
           { root: true }
         );

         try {
           const ausgaben2 = await AusgabenDataModel.deepByID(id, 1);
           if (ausgaben2?.length > 0) {
             //
             // Get the first one for some reference data …
             //
             const first = ausgaben2[0];
             let ztg: ZeitschriftFlatModel;

             if (this.ztg.has(first.ztg)) {
               catVue.debug(`Re-using stored Zeitschrift for ${first.ztg}`);
               ztg = this.ztg.get(first.ztg) as ZeitschriftFlatModel;
             } else {
               const ztg2 = await ZeitschriftFlatModel.byID(first.ztg);

               if (null === ztg2) {
                 catVue.warn("Nope! " + JSON.stringify(ztg2));
                 throw "Nothing found -> null";
               }
               ztg = ztg2;
               this.ztg.set(first.ztg, ztg);
             }
             //
             //
             let menge = new MengenDisplay(id, first, ztg, ausgaben2);
             catVue.debug(`Pushing ... Ausgabe ID = ${id}`);
             this.mengen.push(menge);

             menge.fillVorausgabe(0);
             menge.fillNachausgabe();
             menge.fillLetzteDispo();
             this.$nextTick(() => menge.fillVorausgabe(1));
             //
           } else {
             catVue.info(
               `Strange — should have had some results ... Ausgabe ID = ${id}`
             );
           }
           //
         } catch (e) {
           catVue.error("Error: " + e, null);
           this.$store.commit("changeAppLoadingState", false, {
             root: true,
           });
           return;
         }
       },
     },

     computed: {
       loadingText(): string {
         if (this.toFetch.length > 0)
           return `Lade ... ${this.mengen.length}  Einträge, ${this.toFetch.length} offen`;
         else return `Reste ... ${Api.countWaiting()}  Anfragen laufen noch`;
       },

       ...mapState(["isAppLoading"]),
       ...mapState("auth", ["filialenAlt"]),
     },

     watch: {
       $route(to: Route, from: Route) {
         catNav.info(
           `${this.$options.name}:: Route '${from.fullPath}' > '${to.fullPath}'`
         );
       },

       von: "check",
       bis: "check",
     },

     created() {
       catVue.info(
         `${this.$options.name}:: CREATED ... von ${this.von} bis ${this.bis} ... `
       );
     },

     mounted() {
       catVue.info(
         `${this.$options.name}:: MOUNTED ... von ${this.von} bis ${this.bis} ... `
       );

       this.check();
     },

     beforeDestroy() {
       catVue.info(
         `${this.$options.name}:: BEFOREDESTROY ... mit ${this.toFetch.length} to process ... `
       );

       this.clearAll();
     },
   });
