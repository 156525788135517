import { Exclude, Expose } from "class-transformer";

import { BaseType } from "./util/BaseType";
import { api } from "@/util/api";
import { catApi } from "@/util/logging";

export class ZeitschriftFlatModel extends BaseType {

   @Expose() uid = '';
   @Expose() url = '';
   @Expose() id = -1;
   @Expose() obj_id = '';
   @Expose() titel = '';
   @Expose() verkauf = 0.0;
   @Expose() knd_typ = '';
   @Expose() mwst = 0;
   @Expose() erscheinen = '';
   @Expose() ausgaben_pro_jahr = 0;
   @Expose() vdz_nr = 0;
   @Expose() vdz_ta: number | null = 0;
   @Expose() vdz_tag = 0;
   @Expose() verlag = '';
   @Expose() v_anschrift = '';
   @Expose() ver_id = '';
   @Expose() vca_id: number | null = 0;

   @Exclude() $created = Date.now();



   // === === === === === === === === === === === === === === === ===

   static byID = async (id: number): Promise<ZeitschriftFlatModel | null> => {

      return ZeitschriftFlatModel.byURL(`/zeitschriften/${id}`)
   }

   static byURL = async (url: string): Promise<ZeitschriftFlatModel | null> => {

      catApi.debug("byID: " + url);
      try {
         const path = `v1${url}`
         const response = await api.get<ZeitschriftFlatModel[]>(path);
         catApi.debug(`response: ${path} -> ${JSON.stringify(response)}`);

         return BaseType.convert(ZeitschriftFlatModel, response.data)
      }
      catch (e) {

         const err = new Error(JSON.stringify(e))
         catApi.error("caught Error: " + err.message, err);
         return Promise.reject(err)
      }
   }

   patch = async (action: string): Promise<ZeitschriftFlatModel | null> => {

      catApi.debug(this.url + " ... patch: " + action);
      try {
         const options = { params: { 'action': action } }
         const path = `v1${this.url}`
         const response = await api.patch<ZeitschriftFlatModel[]>(path, undefined, options);
         catApi.debug(`response: ${path} -> ${JSON.stringify(response)}`);

         return BaseType.convert(ZeitschriftFlatModel, response.data)
      }
      catch (e) {

         const err = new Error(JSON.stringify(e))
         catApi.error("caught Error: " + err.message, err);
         return Promise.reject(err)
      }
   }
}

